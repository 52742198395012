import React, { useState } from 'react';
import './RoomPanel.css';
import PlayersContent from './components/PlayersContent/PlayersContent';
import InvitationsContent from './components/InvitationsContent/InvitationsContent';

const RoomPanel = () => {
    const [activeButton, setActiveButton] = useState('players');
    const handleOnClick = (type) => setActiveButton(type)
 
    return(
        <section className='room-panel'>
            <div className='settings-nav'>
                <span onClick={ () => handleOnClick("players")} className={`action-button ${activeButton === 'players' ? 'active' : ''}`} >Gracze</span>
                <span onClick={ () => handleOnClick("invitations")} className={`action-button ${activeButton === 'invitations' ? 'active' : ''}`} >Zaproszenia</span>
            </div>

            {activeButton === "players" ? <PlayersContent /> : <InvitationsContent />}   
        </section>

    );
};

export default RoomPanel;
