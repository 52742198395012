import React, { useEffect, useState } from 'react';
import request from '../../../../helpers/request';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import './GroupsPanel.css';
import GroupItem from './components/GroupItem/GroupItem';
import LoadingPanel from '../../../../components/LoadingPanel/LoadingPanel';


const GroupsPanel = () => {

    const [groups, setGroups] = useState([]);
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const [loading, setLoading] = useState(true);
    const user_id = decodedToken.id;
    const navigate = useNavigate();

    const getGroups = () => {
        setLoading(true); 
        request.get(`/groups/${user_id}`,{
            headers: {
                'Authorization': token
            }
        })
        .then((res => {
            setGroups(res.data.groups);
            setLoading(false); 
        }))
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
              alert(message);
            }   else if ((status === 401) || (status === 402)){
                navigate('/user/rooms');
            }
        });
    };

    useEffect(() => {
        getGroups();
    }, []);

    const groupsList = groups.map((group) => ( <GroupItem key={group.group}  group={group}/>))

    return (
        loading ? ( 
            <LoadingPanel />
        ) : (
            <>
            {groupsList}
            <div className='description-item'>
                <span className='fifa-description'>(16)</span>
                <span className='description'>- Pozycja w rankingu FIFA</span>
            </div>
           </>
            
        )

    );
};

export default GroupsPanel;