import React from 'react';
import './ListItem.css';

import { RiDeleteBin6Line } from "react-icons/ri";

const ListItem = (props) => {
    const {email,action , isAction, id, nr} = props;
    return (
        <div className='list-item'> 
            <span className='nr'>{nr + 1}</span>
            <span className='email'>{email}</span>
            <span className='action'>
                {isAction ? <RiDeleteBin6Line className='remove-player-icon' onClick={()=>action("Czy napewno chcesz usunąć gracza?", id)}/> : null}
            </span>
            
        </div>
    );
};

export default ListItem;