import React from 'react';
import './NotFoundPage.css';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import NotFoundImage from '../../icons/not_found.png';
const NotFoundPage = () => {
    return (
        <section className='not-found-page'>
            <UITemplate title={"Nie zanleziono strony"}>
                <img src={NotFoundImage} alt="" />
            </UITemplate>    
        </section>
    );
};

export default NotFoundPage;