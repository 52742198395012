import React, { useState } from 'react';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { useNavigate, useParams } from 'react-router-dom';
import BetItem from '../BetItem/BetItem';
import ChangeBetItem from '../ChangeBetItem/ChangeBetItem';
import BettedItem from '../BettedItem/BettedItem';
import request from '../../../../helpers/request';
import { FaRegSadTear } from "react-icons/fa";
import './MyBetsPanel.css';

const MyBetsPanel = (props) => {
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const { id } = useParams(); 
    const [loading, setLoading] = useState(false); 
    
    const navigate = useNavigate();

    const { matches, getMatches, handleOpenModal, handleOpenErrorModal } = props;
    const [betValues, setBetValues] = useState({});

    const handleBetChange = (matchId, team, value) => {
        setBetValues(prevState => ({
            ...prevState,
            [matchId]: {
                ...prevState[matchId],
                [team]: value
            }
        }));
    };


    const addBet = async (match_id, bet_team_1, bet_team_2 ) => {
        if (loading) return; 
        setLoading(true);
        try {
            await request.post(`/bets/add/${user_id}`, {
                roomName: id,
                match_id,
                bet_team_1,
                bet_team_2,
            }, {
                headers: {
                    'Authorization': token
                }
            });
            getMatches(new Date());
            handleOpenModal("Pomyślnie oddano typ");
            setBetValues({});
        } catch (e) {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message);
                getMatches(new Date());
            } else if (status === 401) {
                navigate('/user/rooms');
            }
        } finally {
            setLoading(false);
        }
    };

const updateBet = (bet_id, match_id, bet_team_1, bet_team_2 ) => {
    request.post(`/bets/update/${user_id}`, {
        bet_id,
        roomName: id,
        match_id,
        bet_team_1,
        bet_team_2,
    },{
        headers: {
            'Authorization': token
        }})
    .then((res => {
        handleOpenModal("Pomyślnie zmieniono typ");
        getMatches(new Date());
    }))
    .catch(e => {
        const { status, message } = e.response.data;
        if (status === 400) {
          handleOpenErrorModal(message)
          getMatches(new Date());
        } else if (status === 401){
            navigate('/user/rooms');
        }
      });
}

    const matchesList = matches ?  matches.map((match) => { 
        const matchId = match._id;
        const betTeam1 = betValues[matchId]?.betTeam1 ?? (match.bet_team_1 ?? "");
        const betTeam2 = betValues[matchId]?.betTeam2 ?? (match.bet_team_2 ?? "");
        
        return (

            match.isEditable  ? (
                match.betted ? (
                    <ChangeBetItem key={match._id} match={match} betTeam1={betTeam1} betTeam2={betTeam2} matchId={matchId} handleBetChange={handleBetChange} action={updateBet} />
                ) : (
                    <BetItem key={match._id} match={match} betTeam1={betTeam1} betTeam2={betTeam2} matchId={matchId} handleBetChange={handleBetChange} action={addBet} />
                )
            ) : (
                    <BettedItem key={match._id}  match={match} betTeam1={betTeam1} betTeam2={betTeam2}/>

            )
        );
    })
    : null;


    return (
        matchesList.length > 0  ? matchesList : <p className='lack-of-matches'><FaRegSadTear className='sad-icon'/> <span>Brak meczów</span></p>
    );
};

export default MyBetsPanel;
