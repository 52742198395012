import React from 'react';
import { format } from 'date-fns';
import './BetItem.css';




const BetItem = (props) => {
    const {match, betTeam1, betTeam2, matchId ,handleBetChange, action } = props;

    const {team_1, team_2, start} = match;

    const startDate = start && format(start, "HH:mm");
    
    return (
        <div className='bet-item'>
            <span className='top'>
                    <span className='time'>{startDate}</span>
                </span>

            <span className='middle'>
            <span className='flag'>
                <img src={`/Assets/images/flags/${team_1}.svg`} alt="" />
            </span>
                    <span className='country-name'>{team_1}</span>
                    <select className="score" onChange={(e) => handleBetChange(matchId, 'betTeam1', e.target.value)} value={betTeam1}>
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    <span className='separator'>:</span>
                    <select className="score" onChange={(e) => handleBetChange(matchId, 'betTeam2', e.target.value)} value={betTeam2}>
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>

                    <span className='country-name'>{team_2}</span>
            
                <span className='flag'>
                <img src={`/Assets/images/flags/${team_2}.svg`} alt="" />
            </span>
            </span>
            <span className='bottom'>
                <span onClick={()=> action(matchId, betTeam1,betTeam2)} className='action'>Typuj</span>
            </span>

        </div>
    );
};

export default BetItem;