import React from 'react';

import './ArticlePanel.css';

const ArticlePanel = ({title, children}) => {
    return (
        <article id={title} className='article-panel'>
            <span className='title'>{title}</span>
            {children}
        </article>
    );
};

export default ArticlePanel;