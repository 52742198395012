import React from 'react';
import { Link } from 'react-router-dom';

import './ListElement.css';

const ListElement = ({roomName, isOwner}) => {
    return (
        <Link to={roomName} className='list-element' >
            {roomName} 
            {isOwner && <span className='owner-title'>(właściciel)</span>}
        </Link>
    )
};

export default ListElement;