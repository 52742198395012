import React, { useEffect, useState } from 'react';

import UITemplate from '../../layouts/UITemplate/UITemplate';

import './RoomOfficialScoresPage.css';

import GroupsPanel from './components/GroupsPanel/GroupsPanel';
import OfficialScorePanel from './components/OfficialScorePanel/OfficialScorePanel';
import BracketPanel from './components/BracketPanel/BracketPanel';

const RoomOfficialScoresPage = () => {

    const [activeTab, setActiveTab] = useState('scores')

    const handleTab = (name) => {
        setActiveTab(name)
    }

    return (
        <UITemplate title="Wyniki meczów">  

            <div className='nav-scores'>
                <span onClick={ () => handleTab("scores")} className={ activeTab === 'scores' ? 'item-active' : 'item'}>Wyniki</span>
                <span onClick={ () => handleTab("groups")} className={ activeTab === 'groups' ? 'item-active' : 'item'}>Grupy</span>
                <span onClick={ () => handleTab("bracket")}  className={ activeTab === 'bracket' ? 'item-active' : 'item'}>Drabinka</span>
            </div>
            {activeTab === "scores" ? (
                <OfficialScorePanel/>
                ) : activeTab === "groups" ? (
                <GroupsPanel />
                ) : activeTab === "bracket"  ? (
                    <BracketPanel/>
                ) : null}

            

        </UITemplate>
    );
};

export default RoomOfficialScoresPage;
