import React from 'react';
import './CustomSettiingsInput.css';

const CustomSettiingsInput = ({labelName, valueInput, handleOnChange, typeInput, placeholder , children, isActive}) => {
    return ( 
        <div className={isActive ? `custom-settings-input active` : `custom-settings-input`}>
            <div className='left'>
                <label htmlFor="">{labelName} </label>
                <input disabled={!isActive} onChange={handleOnChange} value={valueInput} type={typeInput} placeholder={placeholder}/>
            </div>
            <div className='right'>
                {children}
            </div>
        </div>

    ); 
};

export default CustomSettiingsInput;