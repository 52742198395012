import React from 'react';
import { HiOutlineLightBulb } from "react-icons/hi";
import { BsDoorClosed, BsTelephone } from "react-icons/bs";
import { IoGameControllerOutline, IoFootball } from "react-icons/io5";
import { TfiCup } from "react-icons/tfi";




import './Article.css';

const Article = ({title, children, iconNumber, img}) => {
    const icons = [<HiOutlineLightBulb className='icon-home'/> , <BsDoorClosed className='icon-home'/> ,<IoGameControllerOutline className='icon-home'/>, <IoFootball className='icon-home'/>, <TfiCup className='icon-home'/>, <BsTelephone className='icon-home'/>]
    return (
        <div className='article'>
            <div className='image'>
                <img src={img} alt="" />
                <span className='title-mini'>{icons[iconNumber]}{title}</span>
            </div>
            <div className='box'>
                {children}
            </div>
            

        </div>
    );
};

export default Article;