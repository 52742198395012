import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import request from '../../helpers/request';
import MyBetsPanel from './components/MyBetsPanel/MyBetsPanel';
import { useNavigate, useParams } from 'react-router-dom';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel'; 

import './RoomMyBetsPage.css';
import SimpleModal from '../../components/SimpleModal/SimpleModal';
import ImageError from '../../icons/lock.png';
import ImageAccept from '../../icons/accept.png';
import DateBar from './components/DateBar/DateBar';

const RoomMyBetsPage = () => {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [selectedDate, setSelectedDate] = useState(new Date());
    const navigate = useNavigate();
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const { id } = useParams(); 

    const [showModalError, setShowModalError] = useState({status: false, message: ""});
    const [showModal, setShowModal] = useState({status: false, message: ""});

    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});

    const handleOpenModal = (text) => setShowModal({status: true, message: text});  
    const handleCloseModal = () => setShowModal({status: false});



    const getMatches = (date) => {
        setLoading(true); 
        request.post(`/matches/player-matches/${user_id}`, {
            room_id: id,
            date,
        },{
            headers: {
                'Authorization': token
            }
        })
        .then((res => {
            setMatches(res.data.matches);
            setLoading(false); 
        }))
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
              alert(message)
            }  else if ((status === 401) || (status === 402)){
                navigate('/user/rooms');
            }
        });
    };

    useEffect(() => {
        getMatches(new Date());
    }, []);

    return (
        <UITemplate title="Moje typy">
            <DateBar getMatches={getMatches} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
            {loading ? ( 
                <LoadingPanel />
            ) : (
                <div className='my-bets-list'>
                    <MyBetsPanel matches={matches} getMatches={getMatches} handleOpenModal={handleOpenModal} handleOpenErrorModal={handleOpenErrorModal} />
                </div>
            )}

        <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
            <img className="icon-modal" src={ImageError} alt="" />
        </SimpleModal>
        <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
            <img className="icon-modal" src={ImageAccept} alt="" />
        </SimpleModal>
        </UITemplate>
    );
};

export default RoomMyBetsPage;
