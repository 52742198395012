import React from 'react';
import './GroupItem.css';
import TableItem from '../TableItem/TableItem';

const GroupItem = ({group}) => {
    const teams = group.teams;

    const teamsList = teams.map((team,index) => (<TableItem key={team.team_code}  team={team} index={index + 1}/> ))
    return (
        <section className='group-item'>
            <div className='group-name'>{group.group}</div>
            <div className='group-table'>
                <div className='table-label'>
                    <span className='rank'>Nr</span>
                    <span className='country'>Kraj</span>
                </div>
                {teamsList}
            </div>
        </section>
    );
};

export default GroupItem;