import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import './RoomBetsPage.css';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import request from '../../helpers/request';
import MatchItem from './components/MatchItem/MatchItem';
import PlayerBetItem from './components/PlayerBetItem/PlayerBetItem';
import SearchMatchPanel from './components/SearchMatchPanel/SearchMatchPanel';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel'; // Import LoadingPanel

const RoomBetsPage = () => {

    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const { id } = useParams(); 
    const navigate = useNavigate();
    const [match, setMatch] = useState();
    const [bets, setBets] = useState([]);
    const [matchId, setMatchId]= useState("");
    const [datesOfMatches, setDateOfMatches] = useState([]);
    const [loading, setLoading] = useState(true); 

    const handleSelectMatchId = (e) => {
        setMatchId(e.target.value);
        getMatchBets(e.target.value);
    }

    const getLatestBets = () => {
        request.post(`/matches/players-latest-bets/${user_id}`, {
            room_id: id
        },{
            headers: {
                'Authorization': token
            }
        })
        .then((res => {
            setBets(res.data.bets);
            setMatch(res.data.latestMatch);
            setDateOfMatches(res.data.dateListOfMatches);
            setLoading(false);
        }))
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
              alert(message)
            }   else if ((status === 401) || (status === 402)){
                navigate('/user/rooms');
            }
        });
    }

    const getMatchBets = (match_id) => {
      setLoading(true); 
        request.post(`/matches/players-bets/${user_id}`, {
            room_id: id,
            match_id
        },{
            headers: {
                'Authorization': token
            }
        })
        .then((res => {
            setBets(res.data.bets);
            setMatch(res.data.match);
            setLoading(false); 
        }))
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
              alert(message)
            }  else if ((status === 401) || (status === 402)){
                navigate('/user/rooms');
            }
        });
    }

    useEffect(() => {
        getLatestBets();
    }, []);

    const betsList = bets.length > 0 ? bets.map((bet) => <PlayerBetItem key={bet.bet_id} bet={bet}/>)  : "Brak typów";

    const datesOfMatchesList = datesOfMatches.length > 0 ? (
        datesOfMatches.map((match) => {
          const date = match.start && format(match.start, 'yyyy-MM-dd');
          return (
            <option key={match._id} value={match._id}>
              {match.team1_code} - {match.team2_code} | {date}
            </option>
          );
        })
      ) : (
        <option>Brak meczów</option>
      );

    return (
        <UITemplate title="Typy graczy">
            {loading ? ( 
                <LoadingPanel />
            ) : (
                <>
                <SearchMatchPanel datesOfMatchesList={datesOfMatchesList} matchId={matchId} handleSelectMatchId={handleSelectMatchId}/>
                {match && ( 
                <>
                <MatchItem match={match} />
                <span>Liczba typów: {bets.length}</span>
                    <div className='bets-list'>
                            {betsList}
                    </div>
                    </>          
                )}
                
            </>
            )}
        </UITemplate>
    );
};

export default RoomBetsPage;
