import React from 'react';
import { format } from 'date-fns';
import './OfficialScoreItem.css';




const OfficialScoreItem = ({match}) => {

    const {team_1, team_2, start} = match;

    const startDate = start && format(start, "HH:mm");
    
    return (
        <div className='official-score-item'>
            <span className='top'>
                    <span className='time'>{startDate}</span>
                </span>

            <span className='bottom'>
            <span className='flag'>
                <img src={`/Assets/images/flags/${team_1.code}.svg`} alt="" />
            </span>
                    <span className='country-name'>{team_1.code}</span>
                    <span className='score'>{team_1.score}</span>
                    <span className='separator'>:</span>
                    <span className='score'>{team_2.score}</span>
                    <span className='country-name'>{team_2.code}</span>
            
                <span className='flag'>
                    <img src={`/Assets/images/flags/${team_2.code}.svg`} alt="" />
                </span>
            </span>

        </div>
    );
};

export default OfficialScoreItem;