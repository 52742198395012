import React from 'react';

import './RankingLabel.css'
const RankingLabel = () => {
    return (
        <div className='ranking-label'>
            <span className='rank'>Poz.</span>
            <span className='name'>Gracz</span>
            <span className='wrapper'>
                <span className='box'>D</span>
                <span className='box'>T</span>
                <span className='box'>N</span>
            </span>
            <span className='total-prize'>Pkt.</span>
        </div>
    );
};

export default RankingLabel;