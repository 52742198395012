import React, { useContext, useState } from 'react';
import Cookies from 'js-cookie';
import request from '../../helpers/request';
import LoginPanel from './components/LoginPanel/LoginPanel';
import { AuthContext } from '../../helpers/AuthContext';
import { IoArrowBackOutline } from "react-icons/io5";
import './LoginPage.css';

import ImageError from '../../icons/lock.png';
import ImageAccept from '../../icons/accept.png';
import SimpleModal from '../../components/SimpleModal/SimpleModal';
import { IoIosFootball } from "react-icons/io";

const LoginPage = () => {
    const { setIsLoggedIn } = useContext(AuthContext);
    const [loading, setLoading] = useState(false); 
    const [showModalError, setShowModalError] = useState({status: false, message: ""})
    const [showModal, setShowModal] = useState({status: false, message: ""})

    const [activePanel, setActivePanel] = useState("login");
    

    const handleOpenErrorModal = (text) => {
        setShowModalError({status: true, message: text})
      }
      
     const handleCloseErrorModal = () => {
        setShowModalError({status: false})
      }

      const handleOpenModal = (text) => {
        setShowModal({status: true, message: text})
      }
      
     const handleCloseModal = () => {
        setShowModal({status: false})
      }


    const handleOnActivePanel = (active) => setActivePanel(active);

    const setTokenInCookie = (token) => {
        Cookies.set('access_token', token, { expires: 30 });
    };


    const sendLoginRequest = (e, loginForm) => {
        e.preventDefault();
        setLoading(true); 
        request.post('/auth/login', loginForm)
            .then(res => {
                const token = res.data.access_token;
                if (token) {
                    setTokenInCookie(token);
                    setIsLoggedIn(true);
                }
            })
            .catch(e => {
                const { code, message } = e.response.data;
                if (code === 400) {
                    handleOpenErrorModal(message);
                }
            })
            .finally(() => {
                setLoading(false); 
            });
    };

    const sendResetRequest = (e, email) => {
        e.preventDefault();
        setLoading(true); 

        request.post('/auth/reset-password', {email})
            .then(res => {
                handleOpenModal("Prośba o zmiane została wysłana na email");
                setActivePanel("login");

            })
            .catch(e => {
                const { code, message } = e.response.data;
                if (code === 400) {
                    handleOpenErrorModal(message);
                }
            })
            .finally(() => {
                setLoading(false); 
            });
    };

    const sendCreateAccountRequest = (e, createAccountForm) => {
        e.preventDefault();
        setLoading(true);
        request.post('/auth/create-account', createAccountForm)
            .then(res => {
                setActivePanel("login");
                handleOpenModal("Konto zostało pomyślnie utworzone. Na Twój adres e-mail została wysłana wiadomość w celu aktywacji (wiadomość mogła trafić do spamu). Czas na aktywację wynosi 30 minut - po tym czasie konto zostanie usunięte.")
            })
            .catch(e => {
                const { code, message } = e.response.data;
                if (code === 400) {
                    handleOpenErrorModal(message);
                }
            })
            .finally(() => {
                setLoading(false); 
            });
    };

    return (
        <section className='login-page'>
            <div className='banner'>
                {(activePanel === "register") || (activePanel === "reset") ? <IoArrowBackOutline onClick={()=> handleOnActivePanel("login")} className='arrow-icon'/> : null}
                <IoIosFootball/>
                <span>TYPER</span>


            </div>
            <LoginPanel 
                loading={loading}
                activePanel={activePanel}
                handleOnActivePanel={handleOnActivePanel}
                sendLoginRequest={sendLoginRequest}
                sendCreateAccountRequest={sendCreateAccountRequest}
                sendResetRequest={sendResetRequest}
            />
            <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>
            <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
                <img className="icon-modal" src={ImageAccept} alt="" />
            </SimpleModal>


        </section>
    );
};

export default LoginPage;
