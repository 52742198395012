import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { RiEditFill } from "react-icons/ri";

import request from '../../../../helpers/request';
import CustomSettiingsInput from '../CustomSettiingsInput/CustomSettiingsInput';

import './RoomInfoPanel.css'

import ImageError from '../../../../icons/lock.png';
import ImageAccept from '../../../../icons/accept.png';
import SimpleModal from '../../../../components/SimpleModal/SimpleModal';



const RoomInfoPanel = () => {
    const { id } = useParams();
    const token = Cookies.get('access_token')
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const navigate = useNavigate();

    const [showModalError, setShowModalError] = useState({status: false, message: ""});
    const [showModal, setShowModal] = useState({status: false, message: ""});

    const [roomName, setRoomName] = useState('');
    const [nickname, setNickname] = useState('');

    const [isActive, setIsActive] = useState(false);

    const handleOpenErrorModal = (text) => {
        setShowModalError({status: true, message: text})
      }
      
     const handleCloseErrorModal = () => {
        setShowModalError({status: false})
      }

      const handleOpenModal = (text) => {
        setShowModal({status: true, message: text})
      }
      
     const handleCloseModal = () => {
        setShowModal({status: false})
      }

    const getRoomInfo = () => {    
        request.post(`/rooms/info/${user_id}`,
            { roomName: id },
            { headers: { 'Authorization': token } }
        )
        .then(res => {
            setRoomName(res.data.room.roomName)
            setNickname(res.data.room.nickname)
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 401){
                navigate('/user/rooms');
            }
        });
    };

    const changeNickname = () => {    
        request.post(`/rooms/change-nickname/${user_id}`,
            { 
                roomName: id,
                nickname,
             },
            { headers: { 'Authorization': token } }
        )
        .then(res => {
            getRoomInfo();
            setIsActive(false);
            handleOpenModal("Pomyślnie zmieniono nazwę")
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message)
            } else if (status === 401){
                navigate('/user/rooms');
            }
        });
    };

    const handleOnChange = (e) => {
        if(e.target.value.length < 13)
            setNickname(e.target.value);
    }

    useEffect(()=>{
        getRoomInfo();
    },[])
    return (
        <div className='room-info-panel'>
            <p>Pokój {roomName}</p>
            <CustomSettiingsInput labelName="Nazwa gracza w pokoju"  handleOnChange={handleOnChange} valueInput={nickname} placeholder={"Podaj nazwę gracza"} isActive={isActive} setIsActive={setIsActive}> 
            <RiEditFill onClick={()=> setIsActive(!isActive)}/>
            </CustomSettiingsInput>
            {isActive ?
            <div className='change-nickname-button' onClick={changeNickname}>Zmień nazwę</div>
            :
            <div className='disabled-change-nickname-button'>Zmień nazwę</div>
            }
           <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>
            <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
                <img className="icon-modal" src={ImageAccept} alt="" />
            </SimpleModal>
            
        </div> 
    );
};

export default RoomInfoPanel;