import React, { useState } from 'react';
import './ActivateAccountPanel.css';
import CustomLoginButton from '../../../LoginPage/components/CustomLoginButton/CustomLoginButton';
import CustomLoginInput from '../../../LoginPage/components/CustomLoginInput/CustomLoginInput';
import ImageActivate from '../../../../icons/activate.png'
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const ActivateAccountPanel = (props) => {
    const {activateAccount} = props;

    return (
        <section className='activate-account-panel'>
            <img className="activate-image" src={ImageActivate} alt="" />
            <div className='activate-text'>Aktywuj dostęp do konta teraz</div>
            <div className='activate-text'>i ciesz się dobrą zabawą.</div>
            <br/>
            <br/>
            <CustomLoginButton action={activateAccount} text="Stwórz konto" >
                Aktywuj konto
            </CustomLoginButton>
        </section>
    )
};

export default ActivateAccountPanel;