import React from 'react';
import { connect } from 'react-redux';
import { togglePanel } from '../../helpers/actions';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import './Navigation.css';

const Navigation = ({ isPanelActive, togglePanel }) => {
  const handleActiveMenu = () => {
    togglePanel(); 
    const menu = document.querySelector('.navigation-menu');
    menu.classList.toggle('active');
    const body = document.querySelector('body');
    body.classList.toggle('menu-open');
  };

  return (
    <div onClick={handleActiveMenu} className='navigation'>{!isPanelActive ? <IoMdMenu/> : <IoMdClose/> }</div>
  );
};

const mapStateToProps = (state) => ({
  isPanelActive: state.app.isPanelActive,
});

const mapDispatchToProps = {
  togglePanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
