import React from 'react';
import { format } from 'date-fns';
import './BettedItem.css';




const BettedItem = ({match, betTeam1, betTeam2}) => {

    const {team_1, team_2, start} = match;

    const startDate = start && format(start, "HH:mm");
    
    return (
        <div className='betted-item'>
            <span className='top'>
                    <span className='time'>{startDate}</span>
                </span>

            <span className='bottom'>
            <span className='flag'>
                <img src={`/Assets/images/flags/${team_1}.svg`} alt="" />
            </span>
                    <span className='country-name'>{team_1}</span>
                    <span className='score'>{betTeam1}</span>
                    <span className='separator'>:</span>
                    <span className='score'>{betTeam2}</span>
                    <span className='country-name'>{team_2}</span>
            
                <span className='flag'>
                    <img src={`/Assets/images/flags/${team_2}.svg`} alt="" />
                </span>
            </span>

        </div>
    );
};

export default BettedItem;