import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import './OrdersPage.css';
import OrderItem from './components/OrderItem/OrderItem';
import request from '../../helpers/request';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel'; 
import { useNavigate } from 'react-router-dom';

const OrdersPage = () => {
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true); 

    const getOrders = () => {
        request.get(`/orders/user/${user_id}`,{
            headers: {
                'Authorization': token
            }
        })
        .then(res => {
            setOrders(res.data.orders);
            setLoading(false); 
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
              alert(message)
            }else if (status === 401){
                navigate('/user/rooms');
            }
        });
    };

    useEffect(() => {
        getOrders();
    }, []);

    const ordersList = orders.length > 0 ? orders.map((order) => (
        <OrderItem key={order._id} orderId={order._id} product={order.product} date={order.date} price={order.price}/>
    )) : <span className='lack-of-orders'>Brak zamówień</span>;

    return (
        <UITemplate title="Zamówienia"> 
            {loading ? <LoadingPanel /> : ordersList} 
        </UITemplate>
    );
};

export default OrdersPage;
