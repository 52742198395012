import React from 'react';
import './CustomLoginInput.css';

const CustomLoginInput = (props) => {
    const {labelName, valueInput, handleOnChange, typeInput, placeholder } = props;
    return (
        <div className='custom-login-input'>
            <label htmlFor="">{labelName} </label>
            <input onChange={handleOnChange} value={valueInput} type={typeInput} placeholder={placeholder}/>
        </div>

    ); 
};

export default CustomLoginInput;