import React from 'react';
import UserContent from '../../layouts/UserContent/UserContent';
import './UserPage.css'


const UserPage = () => {

    return (
        <section className='userpage-main'>
                    <UserContent />
        </section>
    )
};

export default UserPage; 