import React, { useEffect, useState } from 'react';
import request from '../../helpers/request';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import { RiEditFill } from "react-icons/ri";

import './MyProfilePage.css';
import CustomProfileInput from './components/CustomProfileInput/CustomProfileInput';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel'; // Import LoadingPanel
import { useNavigate } from 'react-router-dom';
import SimpleModal from '../../components/SimpleModal/SimpleModal';

import ImageError from '../../icons/lock.png';
import ImageAccept from '../../icons/accept.png';

const MyProfilePage = () => {
    const [token, setToken] = useState(Cookies.get('access_token'));
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValidate, setValidate] = useState(true);
    const [message, setMessage] = useState("");
    const [isActive, setIsActive] = useState({ name: false, email: false, password: false });
    const [loading, setLoading] = useState(true);


    const [showModalError, setShowModalError] = useState({status: false, message: ""});
    const [showModal, setShowModal] = useState({status: false, message: ""});


    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});

    const handleOpenModal = (text) => setShowModal({status: true, message: text});  
    const handleCloseModal = () => setShowModal({status: false});

    const handleNameChange = (e) => {
        if(e.target.value.length < 13)
            setName(e.target.value);
    }
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);



    const handleEditClick = (field) => {
        setIsActive({ ...isActive, [field]: !isActive[field] });
        setMessage("");
        setValidate(true);
    }

    


    const getUserInfo = () => {    
        request.get(`/user/${user_id}`,
            { headers: { 'Authorization': token } }
        )
        .then(res => {
            setName(res.data.user.name)
            setEmail(res.data.user.email)
            setLoading(false); 
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message)
            }else if (status === 401){
                navigate('/user/rooms');
            }
        });
    };

    const changeName = () => {   
        if (!name) {
            setValidate(false); 
            setMessage("Brak uzupełnionych danych!");
            return;
        } 
        setValidate(true);
        setMessage("");

        request.post(`/user/change-name/${user_id}`,
            {name},
            { headers: { 'Authorization': token } }
        )
        .then(res => {
            setName(res.data.user.name)
            handleEditClick('name');
            handleOpenModal("Pomyślnie zmieniono imię");
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message)
            }else if (status === 401){
                navigate('/user/rooms');
            }
        });
    };
    const changeEmail = () => {  
        const validateEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        if(!validateEmail(email)) {
            setValidate(false); 
            setMessage("Podaj poprawny email");
            return;
        }

        
        if (!email) {
            setValidate(false); 
            setMessage("Brak uzupełnionych danych!");
            return;
        }
        setValidate(true);
        setMessage("");

        request.post(`/user/change-email/${user_id}`,
            {email},
            { headers: { 'Authorization': token } }
        )
        .then(res => {
            setEmail(res.data.user.email)
            handleEditClick('email');
            handleOpenModal("Pomyślnie zmieniono email");
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message)
            }else if (status === 401){
                navigate('/user/rooms');
            }
        });
    };


    const changePassword = () => {

        if (!password || !confirmPassword) {
            setValidate(false); 
            setMessage("Brak uzupełnionych danych!");
            return;
        }

        if (password !== confirmPassword) {
            setValidate(false)
            setMessage("Hasła nie są takie same!");
            return;
        }

        setValidate(true);
        setMessage("");

    
        request.post(`/user/change-password/${user_id}`,
            { password },
            { headers: { 'Authorization': token } }
        )
        .then(res => {
            handleEditClick('password');
            setPassword("");
            setConfirmPassword("");
            handleOpenModal("Pomyślnie zmieniono hasło");
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                alert(message);
            } else if (status === 401){
                navigate('/user/rooms');
            }
        });
    };

    useEffect(() => {
        getUserInfo()
    }, []);

    return (
        <UITemplate title={"Mój profil"}>

{loading ? ( 
                <LoadingPanel />
            ) : (
                <>
                    <CustomProfileInput labelName={"Imię"} valueInput={name} handleOnChange={handleNameChange} typeInput={"text"} placeholder={"Podaj imię (maks. 12 znaków)"} isActive={isActive.name}>
                        <RiEditFill onClick={() => handleEditClick('name')} />
                    </CustomProfileInput>
                    {isActive.name ? <div className='my-profile-confirm-button' onClick={changeName}>Zmień</div> : null}

                    {/* <CustomProfileInput labelName={"Email"} valueInput={email} handleOnChange={handleEmailChange} typeInput={"email"} placeholder={"Podaj email"} isActive={isActive.email}>
                        <RiEditFill onClick={() => handleEditClick('email')} />
                    </CustomProfileInput>
                    {isActive.email && (
                        <>
                            {!isValidate && <span className='error-message'>{message}</span>}
                            <div className='my-profile-confirm-button' onClick={changeEmail}>Zmień</div>
                        </>
                    )} */}

                    <CustomProfileInput labelName={"Zmień hasło"} valueInput={password} handleOnChange={handlePasswordChange} typeInput={"password"} placeholder={"Podaj nowe hasło"} isActive={isActive.password}>
                        <RiEditFill onClick={() => handleEditClick('password')} />
                    </CustomProfileInput>
                    {isActive.password ? (
                        <>
                            <CustomProfileInput 
                                labelName={"Powtórz hasło"} 
                                valueInput={confirmPassword} 
                                handleOnChange={handleConfirmPasswordChange} 
                                typeInput={"password"} 
                                placeholder={"Podaj ponownie hasło"} 
                                isActive={true}
                            />
                            {isValidate ? null : <span className='error-message' >{message}</span> }
                            <div className='my-profile-confirm-button' onClick={changePassword}>Zmień</div>
                        </>
                    ) : null} 
                    <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                        <img className="icon-modal" src={ImageError} alt="" />
                    </SimpleModal>
                    <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
                         <img className="icon-modal" src={ImageAccept} alt="" />
                    </SimpleModal>
                </>
            )}
            

        </UITemplate>
    );
};

export default MyProfilePage;
