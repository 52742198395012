import React, { useEffect, useState } from 'react';
import './InvitationsContent.css';
import ListItem from '../ListItem/ListItem';
import CustomSettiingsInput from '../../../CustomSettiingsInput/CustomSettiingsInput';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { BsFillSendFill } from "react-icons/bs";
import request from '../../../../../../helpers/request';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingPanel from '../../../../../../components/LoadingPanel/LoadingPanel';
import SimpleModal from '../../../../../../components/SimpleModal/SimpleModal';

import ImageError from '../../../../../../icons/lock.png';
import ImageAccept from '../../../../../../icons/accept.png';
import ImageQuestion from '../../../../../../icons/question.png';

import DecisionModal from '../../../../../../components/DecisionModal/DecisionModal';



const InvitationsContent = () => {
    const { id } = useParams();
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [invitations, setInvitations] = useState([]);
    const [invitationsAvailable, setInvitationsAvailable] = useState("");
    const [loading, setLoading] = useState(true); 

    const [showModalError, setShowModalError] = useState({status: false, message: ""});
    const [showModal, setShowModal] = useState({status: false, message: ""});
    const [modalAction, setModalAction] = useState();
    const [showModalDescison, setShowModalDescison] = useState({status: false, message: ""});


    const handleOnChange = (e) => setEmail(e.target.value);

    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});

    const handleOpenModal = (text) => setShowModal({status: true, message: text});  
    const handleCloseModal = () => setShowModal({status: false});

    const handleOpenDecisionModal = (text, id_user) => {
        setShowModalDescison({status: true, message: text});
        setModalAction(()=> ()=> removeInvitation(id_user))
    
    }

    
    const handleCloseDescisionModal = () => setShowModalDescison({status: false});

    const invitePlayer = (e, email) => {
        e.preventDefault();
        request.post(`/rooms/add-player/${user_id}`,
            { roomName: id, email },
            {
                headers: {
                    'Authorization': token
                }
            })
            .then(res => {
                getInvitations();
                setEmail('');
                handleOpenModal("Pomyślnie zaproszono gracza");
            })
            .catch(e => {
                const { status, message } = e.response.data;
                if (status === 400) {
                    handleOpenErrorModal(message);
                    getInvitations();
                } else if (status === 401){
                    navigate('/user/rooms');
                }
            });
    };

    const removeInvitation = (id_user) => {

        request.post(`/rooms/remove-invitation/${user_id}`,
            { roomName: id, _id: id_user },
            {
                headers: {
                    'Authorization': token
                }
            })
            .then(res => {
                getInvitations();
                handleOpenModal("Pomyślnie usunięto gracza")
                
            })
            .catch(e => {
                const { status, message } = e.response.data;
                if (status === 400) {
                    handleOpenErrorModal(message);
                    getInvitations();

                } else if (status === 401){
                    navigate('/user/rooms');
                }
            });

    };

    const getInvitations = () => {
        setLoading(true); 
        request.post(`/rooms/invitations/${user_id}`,
            { roomName: id },
            { headers: { 'Authorization': token } }
        )
            .then(res => {
                setInvitations(res.data.invitations.invitations);
                setInvitationsAvailable(res.data.invitationsAvailable);
                setLoading(false); 
            })
            .catch(e => {
                const { status, message } = e.response.data;
                if (status === 400) {
                    handleOpenErrorModal(message);
                } else if (status === 401){
                    navigate('/user/rooms');
                }
            });
    };

    useEffect(() => {
        getInvitations();
    }, []);

    const invitationsList = invitations && invitations.length > 0 ?
        invitations.map((invitation, index) => <ListItem key={index} nr={index} action={handleOpenDecisionModal} id={invitation._id} email={invitation.email} isAction={true} />) :
        <span className='lack-of-text'>Brak zaproszeń</span>;

    return (
        <div className='invitations-content'>
        {loading ? (
            <LoadingPanel />
        ) : (
            <>
                <form onSubmit={(e) => { invitePlayer(e, email); setEmail("") }}>

                    <span className='number-of-players'>Dostepne zaproszenia: <span>{invitationsAvailable}</span></span>
                    {invitationsAvailable ? <CustomSettiingsInput labelName="Zaproszenie" typeInput="text" handleOnChange={handleOnChange} valueInput={email} placeholder="Wpisz adres email gracza" isActive={true}> <BsFillSendFill onClick={(e)=> {invitePlayer(e, email); setEmail("")}}/> </CustomSettiingsInput> : <div className='room-is-full'>Brak miejsc w pokoju</div>}
                
                </form>
                {invitationsList}
            </>
        )}
            <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>
            <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
                <img className="icon-modal" src={ImageAccept} alt="" />
            </SimpleModal>
            <DecisionModal handleCloseModal={handleCloseDescisionModal} action={modalAction} showModal={showModalDescison}> 
                <img className="icon-modal" src={ImageQuestion} alt="" />
            </DecisionModal>
    </div>
    );
};

export default InvitationsContent;