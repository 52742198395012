import React, { useState } from 'react';

import './ResetPasswordContent.css';
import CustomLoginInput from '../../../CustomLoginInput/CustomLoginInput';
import CustomLoginButton from '../../../CustomLoginButton/CustomLoginButton';
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const ResetPasswordContent = ({sendResetRequest, loading}) => {

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    
    const handleOnChangeEmail = (e) => {
        setEmail(e.target.value.toLowerCase());
    }; 

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) {
            setErrorMessage('Wszystkie pola są wymagane');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMessage('Niepoprawny adres email');
            return;
        }

        sendResetRequest(e, email);
    };


    return(
        <form className='reset-password-content'>
            <span className='title-reset'>Resetuj hasło</span>
            <CustomLoginInput handleOnChange={handleOnChangeEmail} valueInput={email} labelName={"Email"} typeInput={"text"} placeholder={"Wprowadź email"}/>
            <CustomLoginButton action={handleSubmit} text="Resetuj" >
            {loading ? <AiOutlineLoading3Quarters className="loading-icon" /> : "Resetuj"}  
            </CustomLoginButton>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
    );
};

export default ResetPasswordContent;