import React from 'react';

import './CustomLoginButton.css';

const CustomLoginButton = ({ action, text, children }) => {
    return (
        <button className="custom-login-button" onClick={action}>
            {children}
        </button>
    );
};

export default CustomLoginButton;