import React from 'react';
import { useNavigate } from 'react-router-dom';
import successfulImage from '../../../src/icons/success.png';

import './PaymentSuccessfulPage.css';
import UITemplate from '../../layouts/UITemplate/UITemplate';


const PaymentSuccessfulPage = () => {

    const navigate = useNavigate();

    const handleOnClick = () => navigate('/user/rooms')
    return (
        <UITemplate>
            <section className='payment-successful-page'>
            <img src={successfulImage} alt="" />
            <div className='successful-title'>Płatność powiodła się</div>
            <button onClick={handleOnClick} className='back-button'>Powrót</button>
        </section>
        </UITemplate>

    );
};

export default PaymentSuccessfulPage;