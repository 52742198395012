import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import request from '../../helpers/request';
import RoomPanel from './components/RoomPanel/RoomPanel'
import UITemplate from '../../layouts/UITemplate/UITemplate';
import RoomInfoPanel from './components/RoomInfoPanel/RoomInfoPanel';
import IncreaseRoomPanel from './components/IncreaseRoomPanel/IncreaseRoomPanel';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel'; 

import SimpleModal from '../../components/SimpleModal/SimpleModal';

import ImageError from '../../icons/lock.png';
import ImageAccept from '../../icons/accept.png';
import ImageQuestion from '../../icons/question.png';

import DecisionModal from '../../components/DecisionModal/DecisionModal';

import './RoomSettingsPage.css';

const RoomSettingsPage = () => {
    const { id } = useParams();
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const navigate = useNavigate();

    const [isOwner, setIsOwner] = useState(false);
    const [loading, setLoading] = useState(true); 

    const [showModalError, setShowModalError] = useState({status: false, message: ""});
    const [modalAction, setModalAction] = useState();
    const [showModalDescison, setShowModalDescison] = useState({status: false, message: ""});

    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});


    const handleOpenDecisionModal = (text) => {
        setShowModalDescison({status: true, message: text});
        setModalAction(()=> ()=> leaveTheRoom())

    }

    const handleCloseDescisionModal = () => setShowModalDescison({status: false});

    const checkIsOwner = () => {
        request.post(`/rooms/is-owner/${user_id}`, {
            roomName: id
        }, {
            headers: {
                'Authorization': token
            }
        })
        .then(res => {
            setIsOwner(res.data.isRoomOwner);
            setLoading(false); 
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message);
            } else if ((status === 401) || (status === 402)){
                navigate('/user/rooms');
            }
        });
    };

    const leaveTheRoom = () => {
        request.post(`/rooms/leave/${user_id}`, {
            roomName: id
        }, {
            headers: {
                'Authorization': token
            }
        })
        .then(res => {
            navigate("/user/rooms")
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message);
            } else if (status === 401){
                navigate('/user/rooms');
            }
        });
    };

    useEffect(() => {
        checkIsOwner();
    }, []);

    return (
        <UITemplate title="Ustawienia">
            {loading ? (
                <LoadingPanel />
            ) : (
                <>
                    <RoomInfoPanel/>
                    {/* {isOwner && <IncreaseRoomPanel/>} */}
                    {!isOwner && <div onClick={() => handleOpenDecisionModal("Czy na pewno chcesz opuścić pokój?")} className='leave-room-button'>Opuść pokój</div>}
                    {isOwner && <RoomPanel/>}
                </>
            )}

            <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>

            <DecisionModal handleCloseModal={handleCloseDescisionModal} action={modalAction} showModal={showModalDescison}> 
                <img className="icon-modal" src={ImageQuestion} alt="" />
            </DecisionModal>
        </UITemplate>
    );
};

export default RoomSettingsPage;
