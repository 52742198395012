import React, { useState } from 'react';
import './DateBar.css';
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";

const DateBar = ({getMatches, selectedDate, setSelectedDate}) => {
    const getStartOfWeek = (date) => {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    };
    const [currentWeekStart, setCurrentWeekStart] = useState(getStartOfWeek(new Date()));

    const handlePreviousWeek = () => {
        const previousWeekStart = new Date(currentWeekStart);
        previousWeekStart.setDate(previousWeekStart.getDate() - 7);
        setCurrentWeekStart(getStartOfWeek(previousWeekStart));
    };

    const handleNextWeek = () => {
        const nextWeekStart = new Date(currentWeekStart);
        nextWeekStart.setDate(nextWeekStart.getDate() + 7);
        setCurrentWeekStart(getStartOfWeek(nextWeekStart));
    };

    const formatDate = (date) => {
        const dayOfMonth = date.getDate();
        if (dayOfMonth === 1) {
            const options = { month: 'short' };
            return date.toLocaleDateString('pl', options).substring(0, 3);
        } else {
            const options = { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric' };
            return date.toLocaleDateString('pl', options).substring(0, 1);;
        }
    };

    const handleGetDay = (date) => {
        setSelectedDate(date);
        getMatches(date);
    };

    const renderDaysOfWeek = () => {
        const days = [];
        const currentDay = new Date(currentWeekStart);

        for (let i = 0; i < 7; i++) {
            const day = new Date(currentDay);
            day.setDate(day.getDate() + i);
            const formattedDay = formatDate(day);
            const isSelected = selectedDate && selectedDate.toDateString() === day.toDateString();

            days.push(
                <div className='day-item' key={i}>
                    <p className={`day-name ${isSelected ? 'selected' : ''}`}>{formattedDay}</p>
                    <span className={`day ${isSelected ? 'selected' : ''}`} onClick={() => handleGetDay(day)}>{day.getDate()}</span>
                </div>
            );
        }
        return days;
    };

    return (
        <div className='date-bar'>
            <div className='change-date-button' onClick={handlePreviousWeek}><IoChevronBackOutline/></div>
            <div className='days'>
                {renderDaysOfWeek()}
            </div>
            <div className='change-date-button' onClick={handleNextWeek}><IoChevronForward/></div>
        </div>
    );
};

export default DateBar;
