import './App.css';
import Content from '../Content/Content';
import { BrowserRouter as Router} from 'react-router-dom';
import UserNavigation from '../UserNavigation/UserNavigation';
import { AuthProvider} from '../../helpers/AuthContext';
import Navigation from '../Navigation/Navigation';
import NavigationMenu from '../Navigation/components/NavigationMenu/NavigationMenu';
import { Provider } from 'react-redux';
import store from '../../helpers/store';



const App = () => {
  
  return (
    <div className="App">
      <Provider store={store}>
        <AuthProvider>
          <Router>
            <nav>
              <Navigation/>
            </nav>
            <aside>
              <NavigationMenu/>
            </aside>
            <main>
              <UserNavigation/>
              <Content/>
            </main>
            <footer>
            </footer>
          </Router>
        </AuthProvider>
      </Provider>
    </div>
  );
}

export default App;
