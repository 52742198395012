import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams, Navigate } from 'react-router-dom';
import CreateRoomPage from '../../pages/CreateRoomPage/CreateRoomPage.js';
import RoomsListPage from '../../pages/RoomsListPage/RoomsListPage.js';
import RoomSettingsPage from '../../pages/RoomSettingsPage/RoomSettingsPage.js';
import RoomMyBetsPage from '../../pages/RoomMyBetsPage/RoomMyBetsPage.js';
import RoomOfficialScoresPage from '../../pages/RoomOfficialScoresPage/RoomOfficialScoresPage.js';
import { AuthContext } from '../../helpers/AuthContext.js';
import InvitationsPage from '../../pages/InvitationsPage/InvitationsPage.js';
import request from '../../helpers/request.js';
import RoomRankingPage from '../../pages/RoomRankingPage/RoomRankingPage.js';

import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import MyProfilePage from '../../pages/MyProfilePage/MyProfilePage.js';
import RoomBetsPage from '../../pages/RoomBetsPage/RoomBetsPage.js';
import PaymentSuccessfulPage from '../../pages/PaymentSuccessfulPage/PaymentSuccessfulPage.js';
import PaymentFailedPage from '../../pages/PaymentFailedPage/PaymentFailedPage.js';
import OrdersPage from '../../pages/OrdersPage/OrdersPage.js';

const UserContent = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    Cookies.remove('access_token');
    setIsLoggedIn(false);
    navigate('/login');
};

  useEffect(() => {
    if (isLoggedIn && localStorage.getItem('targetPath')) {
        const targetPath = localStorage.getItem('targetPath');
        localStorage.removeItem('targetPath');
        navigate(targetPath);
    }
  
    if (isLoggedIn && location.pathname.startsWith('/user/rooms')) {
        const token = Cookies.get('access_token');
        const decodedToken = jwtDecode(token);
        const roomsAccess = decodedToken.rooms;
        const user_id = decodedToken.id;
            request.post(`/rooms/check-rooms-memberships/${user_id}`, 
            {roomNames: roomsAccess},
            {
            headers: {
                'Authorization': token
            }})
            .then(res => {
              
            })
            .catch(e => {
            const { status, message, access_token } = e.response.data;
            if (status === 403) {
                const newToken = access_token;
                Cookies.set('access_token', newToken);
            } else if (status === 401){
              logout();
            }
            });
    }
  
    if (isLoggedIn && location.pathname === '/login') {
        navigate('/user/rooms');
    }
  
  }, [isLoggedIn, location.pathname]);

  const SecuredRoomPage = () => {
    const { id } = useParams();
    const token = Cookies.get('access_token');
    const decodedToken = token ? jwtDecode(token) : null;
    const roomsAccess = decodedToken ? decodedToken.rooms : [];

    useEffect(() => {
      const hasAccess = roomsAccess.includes(id);
      if (!hasAccess) {
        navigate('rooms'); 
        window.location.reload();
      }
    }, [id, roomsAccess, navigate]);

    if (!roomsAccess.includes(id)) {
      return null; 
    }

    return (
      <Routes>
        <Route path='/' element={<RoomMyBetsPage />} />
        <Route path='/my-bets' element={<RoomMyBetsPage />} />
        <Route path='/ranking' element={<RoomRankingPage />} />
        <Route path='/scores' element={<RoomOfficialScoresPage />} />
        <Route path='/settings' element={<RoomSettingsPage />} />
        <Route path='/players-bets' element={<RoomBetsPage />} />
        <Route path='*' element={<Navigate to="my-bets" />} />
      </Routes>
    );
  };

  return (
    <Routes>
      <Route path='/invitations' element={<InvitationsPage />} />
      <Route path='/failed' element={<PaymentFailedPage />} />
      <Route path='/successful' element={<PaymentSuccessfulPage />} />
      <Route path='/my-profile' element={<MyProfilePage />} />
      <Route path='/orders' element={<OrdersPage/>} />

      
      <Route path='/rooms' element={<RoomsListPage />} /> 
      {/* <Route path='/create-room' element={<CreateRoomPage />} /> */}
      <Route path='/rooms/:id/*' element={<SecuredRoomPage />} />
      <Route path='*' element={<Navigate to="rooms" />} />
    </Routes>
  );
};

export default UserContent;
