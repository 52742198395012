import React, { useState } from 'react';
import CustomLoginInput from '../../../CustomLoginInput/CustomLoginInput';
import CustomLoginButton from '../../../CustomLoginButton/CustomLoginButton';
import './CreateAccountContent.css';
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const CreateAccountContent = (props) => {
    const { sendCreateAccountRequest, handleOnActivePanel, loading} = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleOnChangeName = (e) => {
        const inputValue = e.target.value;
        const isValidInput = /^[a-zA-Z0-9]*$/.test(inputValue);
        if(e.target.value.length < 13 && isValidInput) setName(e.target.value); 
    };
    const handleOnChangeEmail = (e) => {
        setEmail(e.target.value.toLowerCase());
    };

    const handleOnChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleOnChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email || !password || !confirmPassword || !name) {
            setErrorMessage('Wszystkie pola są wymagane');
            return;
        }

        if (name.length > 12) {
            setErrorMessage('Imię zbyt długie maks. 12 znaków');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMessage('Niepoprawny adres email');
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage('Hasła nie są identyczne');
            return;
        }

        const createAccountForm = {
            name,
            email,
            password
        };

        sendCreateAccountRequest(e, createAccountForm);
    };

    return (
        <form className='create-account-content'>
            <span className='title-create'>Stwórz konto</span>
            <CustomLoginInput handleOnChange={handleOnChangeName} valueInput={name} labelName={"Imię"} typeInput={"text"} placeholder={"Wprowadź imię (maks. 12 znaków)"} />
            <CustomLoginInput handleOnChange={handleOnChangeEmail} valueInput={email} labelName={"Email"} typeInput={"email"} placeholder={"Wprowadź email"} />
            <CustomLoginInput handleOnChange={handleOnChangePassword} valueInput={password} labelName={"Hasło"} typeInput={"password"} placeholder={"Wprowadź hasło"} />
            <CustomLoginInput handleOnChange={handleOnChangeConfirmPassword} valueInput={confirmPassword} labelName={"Potwierdź hasło"} typeInput={"password"} placeholder={"Potwierdź hasło"} />
            <CustomLoginButton action={handleSubmit} text="Stwórz konto" >
                {loading ? <AiOutlineLoading3Quarters className="loading-icon" /> : "Stwórz konto"}
            </CustomLoginButton>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
    );
};

export default CreateAccountContent;
