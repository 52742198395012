import React, { useState, CSSProperties } from 'react';
import './LoadingPanel.css';
import MoonLoader from "react-spinners/MoonLoader";



const LoadingPanel = () => {


      
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#000");
    return (
        <div className="loading-panel">

  
        <MoonLoader
          color={color}
          loading={loading}
          size={50}
          aria-label="Loading Spinner"
          data-testid="PulseLoader"
          speedMultiplier={0.8}
        />
        Ładowanie...
      </div>
    );
};

export default LoadingPanel;
