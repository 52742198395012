import React, { useEffect, useState } from 'react';
import request from '../../../../helpers/request';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import './BracketPanel.css';
import BracketItem from './components/BracketItem/BracketItem';

import LoadingPanel from '../../../../components/LoadingPanel/LoadingPanel';

const BracketPanel = () => {
    const [bracket, setBracket] = useState([]);
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const [loading, setLoading] = useState(true);
    const user_id = decodedToken.id;
    const navigate = useNavigate();

    const getBracket = () => {
        setLoading(true); 
        request.get(`/matches/bracket/${user_id}`,{
            headers: {
                'Authorization': token
            }
        })
        .then((res => {

            setBracket(res.data.bracket);
            setLoading(false); 
        }))
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
              alert(message);
            }   else if ((status === 401) || (status === 402)){
                navigate('/user/rooms');
            }
        });
    };

    useEffect(() => {
        getBracket();
    }, []);


    return (
        loading ? ( 
            <LoadingPanel />
        ) : (
        <section className='bracket-panel'>
            <div className='row'>
                <div className='box'>
                    <div className='wrapper'>
                        <div className='bracket-box'>
                            <BracketItem tablePosition1="1B" tablePosition2="3ADEF" match={bracket.round_of_16_4.match[0]}/>
                            <BracketItem tablePosition1="1A" tablePosition2="2C" match={bracket.round_of_16_2.match[0]}/>
                        </div>
                        <div className='lines'></div>
                        
                    </div>
                </div>
                <div className='box'>
                    <div className='wrapper'>
                        <div className='bracket-box'>
                            <BracketItem tablePosition1="1F" tablePosition2="3ABC" match={bracket.round_of_16_6.match[0]}/>
                            <BracketItem tablePosition1="2D" tablePosition2="2E" match={bracket.round_of_16_5.match[0]}/>
                        </div>
                        <div className='lines'></div>
                        
                    </div>
                </div>
            </div>
            

            <div className='row'>
                <div className='box'>
                    <div className='wrapper'>
                        <div className='bracket-box'>
                        <BracketItem tablePosition1="W1" tablePosition2="W2" match={bracket.quarter_final_1.match[0]}/>
                        <BracketItem tablePosition1="W3" tablePosition2="W4" match={bracket.quarter_final_2.match[0]}/>

                        </div>
                        <div className='lines'></div>
                        
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='box'>
                    <div className='wrapper'>
                        <div className='bracket-box'>
                        <BracketItem tablePosition1="WQ1" tablePosition2="WQ2" match={bracket.semi_final_1.match[0]}/>
                        </div>
                        <div className='line'></div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <BracketItem tablePosition1="WS1" tablePosition2="WS2" match={bracket.final.match[0]}/>
            </div>
            

            <div className='row part2'>
            <div className='box'>
                    <div className='wrapper'>
                        <div className='line'></div>
                        <div className='bracket-box'>
                        <BracketItem tablePosition1="WQ3" tablePosition2="WQ4" match={bracket.semi_final_2.match[0]}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row part2'>
                <div className='box'>
                    <div className='wrapper'>
                        <div className='lines'></div>
                        <div className='bracket-box'>
                        <BracketItem tablePosition1="W5" tablePosition2="W6" match={bracket.quarter_final_4.match[0]}/>
                        <BracketItem tablePosition1="W7" tablePosition2="W8" match={bracket.quarter_final_3.match[0]}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row part2'>
                <div className='box'>
                    <div className='wrapper'>
                        <div className='lines'></div>
                        <div className='bracket-box'>
                        <BracketItem tablePosition1="1E" tablePosition2="3ABCD" match={bracket.round_of_16_7.match[0]}/>
                        <BracketItem tablePosition1="1D" tablePosition2="2F" match={bracket.round_of_16_8.match[0]}/>
                        </div>
                    </div>
                </div>
                <div className='box'>
                    <div className='wrapper'>
                        <div className='lines'></div>
                        <div className='bracket-box'>
                        <BracketItem tablePosition1="1C" tablePosition2="3DEF" match={bracket.round_of_16_3.match[0]}/>
                        <BracketItem tablePosition1="2A" tablePosition2="2B" match={bracket.round_of_16_1.match[0]}/>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
        )
        );
    };

export default BracketPanel;