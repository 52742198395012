import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import request from '../../../../../../helpers/request';
import ListItem from '../ListItem/ListItem';
import LoadingPanel from '../../../../../../components/LoadingPanel/LoadingPanel';


import SimpleModal from '../../../../../../components/SimpleModal/SimpleModal';

import ImageError from '../../../../../../icons/lock.png';
import ImageAccept from '../../../../../../icons/accept.png';
import ImageQuestion from '../../../../../../icons/question.png';

import DecisionModal from '../../../../../../components/DecisionModal/DecisionModal';

import './PlayersContent.css';

const PlayersContent = () => {
    const { id } = useParams();
    const token = Cookies.get('access_token')
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const navigate = useNavigate();
    const [players, setPlayers] = useState([]);
    const [owner, setOwner] = useState([]);
    const [roomSize, setRoomSize] = useState("");
    const [loading, setLoading] = useState(true); 

    const [showModalError, setShowModalError] = useState({status: false, message: ""});
    const [showModal, setShowModal] = useState({status: false, message: ""});
    const [modalAction, setModalAction] = useState();
    const [showModalDescison, setShowModalDescison] = useState({status: false, message: ""});


    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});

    const handleOpenModal = (text) => setShowModal({status: true, message: text});  
    const handleCloseModal = () => setShowModal({status: false});

    const handleOpenDecisionModal = (text, id_user) => {
        setShowModalDescison({status: true, message: text});
        setModalAction(()=> ()=> removePlayer(id_user))
    }

    
    const handleCloseDescisionModal = () => setShowModalDescison({status: false});

    const getPlayers = () => {    
        setLoading(true); 

        request.post(`/rooms/players/${user_id}`,
            { roomName: id },
            { headers: { 'Authorization': token } }
        )
        .then(res => {
            setPlayers(res.data.players)
            setOwner(res.data.owner)
            setRoomSize(res.data.roomSize)
            setLoading(false); 

        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message);
            } else if (status === 401){
                navigate('/user/rooms');
            }
        });
    };

    const removePlayer = (id_user) => {
          request.post(`/rooms/remove-player/${user_id}`, 
          {roomName: id, _id: id_user},
          {
            headers: {
                'Authorization': token
            }})
          .then(res => {
            getPlayers();
            handleOpenModal("Pomyślnie usunięto gracza")
          })
          .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
             handleOpenErrorModal(message);
            getPlayers();
            } else if (status === 401){
                navigate('/user/rooms');
            }
          });

    };

    const playersList = players.length > 0 ? players.map((player, index) => (<ListItem name="player" nr={index+1} key={index} id={player._id} email={player.email} action={handleOpenDecisionModal} isAction={true}/>)) : null;

    useEffect(()=>{
        getPlayers();
    },[])
    
    return (
        <div className='players-content'>
        {loading ? <LoadingPanel/> : (
        <>
            <span className='number-of-players'>Liczba graczy w pokoju: <span>{playersList ? playersList.length + 1 : 1} / {roomSize}</span></span>
            <ListItem nr={0} name="player" key={owner.email} email={owner.email} isAction={false}/>
            {playersList}
        </>
    )}
            <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>
            <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
                <img className="icon-modal" src={ImageAccept} alt="" />
            </SimpleModal>
            <DecisionModal handleCloseModal={handleCloseDescisionModal} action={modalAction} showModal={showModalDescison}> 
                <img className="icon-modal" src={ImageQuestion} alt="" />
            </DecisionModal>
        </div>
        
    );
};

export default PlayersContent;