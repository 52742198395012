import React from 'react';

import './RankingItem.css';

const RankingItem = ({player}) => {
    const {rank, name, accurate, inaccurate, missed, totalPrize} = player;

    return (
        <div className='ranking-item'>
            <span className='rank'>{rank}</span>
            <span className='name'>{name}</span>
            <span className='wrapper'>
                <span className='box'>{accurate}</span>
                <span className='box'>{inaccurate}</span>
                <span className='box'>{missed}</span>
            </span>
            <span className='total-prize'>{totalPrize}</span>
        </div>
    );
};

export default RankingItem;