import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosFootball } from "react-icons/io";

import BackgroundImage from '../../../../images/backgorund.jpg';
import './Banner.css';

const Banner = () => {
    return (
        <section className='banner'>
                <img src={BackgroundImage} alt="" />
                    <Link className='start-button' to="/user/rooms">
                        <IoIosFootball className='play-icon'/>
                        <span>Zagraj</span> 
                    </Link>
                    <div className='banner-text'><span>Hej, dołącz do społeczności pasjonatów, którzy typują kierując się intuicją, sercem, a&nbsp;czasem rozumem.</span></div>
        </section>
    );
};


export default Banner;