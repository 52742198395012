import React from 'react';
import './SearchMatchPanel.css';
import { IoIosSearch } from "react-icons/io";

const SearchMatchPanel = ({datesOfMatchesList, matchId, handleSelectMatchId}) => {
    return (
        <div className='search-match-panel'>
            <IoIosSearch className='icon-search'/>
            <select value={matchId} onChange={handleSelectMatchId}>
                {datesOfMatchesList}
            </select>
        </div>
    )
}

export default SearchMatchPanel;