import React, { useState } from 'react';
import './ResetPasswordPage.css';
import ResetPasswordPanel from './components/ResetPasswordPanel/ResetPasswordPanel';
import request from '../../helpers/request';
import { useNavigate, useParams } from 'react-router-dom';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import SimpleModal from '../../components/SimpleModal/SimpleModal';
import ImageError from '../../icons/failed.png';



const ResetPasswordPage = () => {
    const { token } = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false); 
    const [showModalError, setShowModalError] = useState({status: false, message: ""})

    const handleOpenErrorModal = (text) => {
        setShowModalError({status: true, message: text})
      }
      
     const handleCloseErrorModal = () => {
        setShowModalError({status: false})
      }


    const changePassword = (password) => {
            request.post(`/user/reset-password`, {
                token,
                password,
            })
              .then(res => {
                navigate('/login')
              })
              .catch(e => {
                const { status, message} = e.response.data;
                if (status === 400) {
                    handleOpenErrorModal(message)
                }
              })
    }

    return (
        <section>
            <UITemplate title={"Zmień hasło"}>
                <ResetPasswordPanel
                    changePassword={changePassword}
                />
                <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                    <img className="icon-modal" src={ImageError} alt="" />
                </SimpleModal>
            </UITemplate>
        </section>
    );
}

export default ResetPasswordPage;