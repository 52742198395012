import axios from 'axios'
const BASE_URL = 'https://typersrv.nieweszlo.pl';
// const BASE_URL = 'http://172.20.21.180:5555';







const request = axios.create({
    baseURL: BASE_URL,
})

export default request