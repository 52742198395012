import React from 'react';

import  './OrderItem.css';

const OrderItem = (props) => {

    const {orderId, product, date, price} = props;
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    const formattedDate = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;

    return (
        <div className='order-item'>
            <div className='top'>
                <span> ID: {orderId}</span>
            </div>
            <div className='bottom'>
                <div className='item'>   
                    <span className='label'>Nazwa</span>
                    <span className='item-content'>{product}</span>
                </div>
                <div className='item'>   
                    <span className='label'>Data</span>
                    <span className='item-content'>{formattedDate}</span>
                </div>
                <div className='item'>   
                    <span className='label'>Cena</span>
                    <span className='item-content'>{price} PLN</span>
                </div>
            </div>
            
        </div>
    );
};

export default OrderItem;