import React from 'react';

import './PlayerBetItem.css';

const PlayerBetItem = ({bet}) => {
    const {player_name, bet_team_1, bet_team_2} = bet;

    return (
        <div className='player-bet-item'>
            <div className='left'>
                <span className='name'>{player_name}</span>
            </div>
            <div className='right'>
                <span className='score'>{bet_team_1}</span>
                <span className='separator'>:</span>
                <span className='score'>{bet_team_2}</span>
            </div>

            </div>
    );
};

export default PlayerBetItem;