import React, { useEffect, useState } from 'react';
import './OfficialScorePanel.css';
import request from '../../../../helpers/request';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import OfficialScoreItem from './components/OfficialScoreItem/OfficialScoreItem';
import LoadingPanel from '../../../../components/LoadingPanel/LoadingPanel'; 
import DateBar from './components/DateBar/DateBar';
import { useNavigate } from 'react-router-dom';
import { FaRegSadTear } from "react-icons/fa";

const OfficialScorePanel = () => {
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const navigate = useNavigate();
    
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const getMatches = (date) => {
        setLoading(true); 
        request.post(`/matches/${user_id}`, {
            date
        },{
            headers: {
                'Authorization': token
            }
        })
        .then((res => {
            setMatches(res.data.matches);
            setLoading(false); 
        }))
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
              alert(message);
            }   else if ((status === 401) || (status === 402)){
                navigate('/user/rooms');
            }
        });
    };


    const listMatches = matches ? matches.map((match) => <OfficialScoreItem key={match._id} match={match} />) : null;

    useEffect(() => {
        getMatches();
    }, []);

    return (
        <section className='official-score-panel'>
        <DateBar getMatches={getMatches} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>

        {loading ? ( 
                <LoadingPanel />
            ) : (
                <div className='official-matches-list'>
                    {listMatches && listMatches.length > 0 ? listMatches : <p className='lack-of-official-matches'><FaRegSadTear className='sad-icon'/> <span>Brak meczów</span></p>}
                </div>
                
            )}
        </section>
    );
};

export default OfficialScorePanel;