import React, { useContext } from 'react';
import './UITemplate.css'


const UITemplate = ({children, title}) => {

    return (
        <section className='ui-template'>
            <div className='header'>{title}</div>
            <div className='content'>{children}</div>
        </section>
    )
};

export default UITemplate; 