import React, { useEffect, useState } from 'react';
import request from '../../helpers/request';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useParams } from 'react-router-dom';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import RankingLabel from './components/RankingLabel/RankingLabel';
import RankingItem from './components/RankingItem/RankingItem';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel';
import ImageError from '../../icons/lock.png';

import './RoomRankingPage.css';
import SimpleModal from '../../components/SimpleModal/SimpleModal';

const RoomRankingPage = () => {
    const { id } = useParams();
    const token = Cookies.get('access_token');
    const [ranking, setRanking] = useState([]);
    const [loading, setLoading] = useState(true); 
    const navigate = useNavigate();

    const [showModalError, setShowModalError] = useState({status: false, message: ""});

    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});

    const getRoomRanking = () => {
        if (token) {
          const decodedToken = jwtDecode(token);
          const user_id = decodedToken.id;
          const roomName = id;

          request.post(`/rooms/ranking/${user_id}`, {roomName}, {
              headers: {
                Authorization: token,
              },
            })
            .then((res) => {
                setRanking(res.data.ranking);
                setLoading(false); 
            })
            .catch((e) => {
              const { status, message } = e.response.data;
              if (status === 400) {
                handleOpenErrorModal(message);
              } else if ((status === 401) || (status === 402)){
                navigate('/user/rooms');
            }
            });
        } else {
            handleOpenErrorModal("Użytkownik niezalogowany");
            setLoading(false); 
        }
      };

    useEffect(() => {
        getRoomRanking();
    }, []); 

    const rankingList = ranking.map(player => <RankingItem key={player._id} player={player}/>)

    return (
        <UITemplate title={"Ranking"}>
            <RankingLabel/>
            {loading ? 
              <LoadingPanel />
            : <div className='ranking'>
              {rankingList}
            </div>
            }

            
<SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>
        </UITemplate>
    );
};

export default RoomRankingPage;
