import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AuthContext } from '../../helpers/AuthContext.js';
import { BsBell, BsDoorClosed, BsArrowBarRight, BsPerson, BsBox } from "react-icons/bs";
import { GiPodiumWinner } from "react-icons/gi";
import { TbScoreboard } from "react-icons/tb";
import { IoIosFootball } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { HiOutlineUsers } from "react-icons/hi2";

import './UserNavigation.css';

import ImageQuestion from '../../icons/question.png';

import DecisionModal from '../../components/DecisionModal/DecisionModal.js';

const UserNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [roomNav, setRoomNav] = useState(false);
    const [roomName, setRoomName] = useState('');
    const [currentPath, setCurrentPath] = useState('');
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

    const [modalAction, setModalAction] = useState();
    const [showModalDescison, setShowModalDescison] = useState({status: false, message: ""});


    const handleOpenDecisionModal = (text) => {
        setShowModalDescison({status: true, message: text});
        setModalAction(()=> ()=> logout())

    }

    const handleCloseDescisionModal = () => setShowModalDescison({status: false});

    const logout = () => {
        Cookies.remove('access_token');
        setIsLoggedIn(false);
        navigate('/');
    };


    useEffect(() => {
            const roomNameFromPath = location.pathname.split('/')[3];
            setCurrentPath(location.pathname);
            if (location.pathname.startsWith('/user/rooms/')) {
                setRoomNav(true); 
                setRoomName(roomNameFromPath);
            } else {
                setRoomNav(false); 
            }
    }, [location.pathname]);

    return (
        isLoggedIn ? (
            <div className='user-navigation'>
                <span className={`${currentPath === '/user/rooms' ? ' active' : ''}`}><Link to='/user/rooms'><BsDoorClosed className={`icon-nav`} /></Link></span>

                {!roomNav ? (
                    <>
                        <span className={`${currentPath.includes('invitations') ? ' active' : ''}`}><Link to='/user/invitations'><BsBell  className={`icon-nav`} /></Link></span>
                        <span className={`${currentPath.includes('orders') ? ' active' : ''}`}><Link to='/user/orders'><BsBox  className={`icon-nav`} /></Link></span>
                        <span className={`${currentPath.includes('/my-profile') ? ' active' : ''}`}><Link to='/user/my-profile'><BsPerson  className={`icon-nav`} /></Link></span>
                        <span><BsArrowBarRight className="icon-nav logout" onClick={()=> handleOpenDecisionModal("Czy napewno chcesz się wylogować?")}/></span>
                    </>
                ) : (
                    <>
                        <span className={`${currentPath.includes('my-bets') || (currentPath.endsWith(roomName) && currentPath.includes('/rooms/')) ? ' active' : ''}`}><Link to={`/user/rooms/${roomName}/my-bets`}><IoIosFootball className={`icon-nav`} /></Link></span>
                        <span className={`${currentPath.includes('players-bets') ? ' active' : ''}`}><Link to={`/user/rooms/${roomName}/players-bets`}><HiOutlineUsers className={`icon-nav`} /></Link></span>
                        <span className={`${currentPath.includes('scores') ? ' active' : ''}`}><Link to={`/user/rooms/${roomName}/scores`}><TbScoreboard className={`icon-nav`} /></Link></span>
                        <span className={`${currentPath.includes('ranking') ? ' active' : ''}`}><Link to={`/user/rooms/${roomName}/ranking`}><GiPodiumWinner className={`icon-nav`} /></Link></span>
                        <span className={`${currentPath.includes('settings') ? ' active' : ''}`}><Link to={`/user/rooms/${roomName}/settings`}><IoSettingsOutline className={`icon-nav`} /></Link></span>
                    </>
                )}

            <DecisionModal handleCloseModal={handleCloseDescisionModal} action={modalAction} showModal={showModalDescison}> 
                <img className="icon-modal" src={ImageQuestion} alt="" />
            </DecisionModal>
            </div>
        ) : null
    );
};

export default UserNavigation;
