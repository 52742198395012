import React, { useState } from 'react';
import './LoginPanel.css';
import LoginContent from './components/LoginContent/LoginContent';
import CreateAccountContent from './components/CreateAccountContent/CreateAccountContent';
import ResetPasswordContent from './components/ResetPasswordContent/ResetPasswordContent';

const LoginPanel = (props) => {
    const {sendLoginRequest, activePanel, handleOnActivePanel, sendCreateAccountRequest, loading,sendResetRequest} = props;
    return (
        <section className='login-panel'>
            <div className='container-login'>
                {activePanel === "register" ? (
                <CreateAccountContent 
                    sendCreateAccountRequest={sendCreateAccountRequest} 
                    handleOnActivePanel={handleOnActivePanel} 
                    loading={loading}
                />
            ) : activePanel === "login" ? (
                <LoginContent 
                    sendLoginRequest={sendLoginRequest} 
                    handleOnActivePanel={handleOnActivePanel} 
                    loading={loading}
                />
            ) : activePanel === "reset" ? (
                <ResetPasswordContent 
                sendResetRequest={sendResetRequest}
                    handleOnActivePanel={handleOnActivePanel} 
                    loading={loading}
                />
            ) : null}
            </div>
        </section>
    );
};

export default LoginPanel;