import React from 'react';
import { FaPeopleGroup } from "react-icons/fa6";

import './InvitationItem.css';
import { IoIosMail } from "react-icons/io";
import { ImCheckmark, ImCancelCircle } from "react-icons/im";


const InvitationItem = (props) => {

    const {invitation, acceptInvitation, cancelInvitation}  = props;

    return (
        <div className='invitation-item'>
            <IoIosMail className='icon-mail'/>
            <span className='room-name'>
                <span className='label'>Nazwa pokoju</span>
                <span className='name'>{invitation.roomName}</span>
            </span>
            <span className='actions'>
                <span className='accept'>
                    <span className='label'>Akceptuj</span>
                    <ImCheckmark className='icon' onClick={() => acceptInvitation("Czy napewno chcesz dołączyć do pokoju?",invitation.roomName, "accept")}/>
                </span>
                <span className='decline'>
                    <span className='label'>Odrzuć</span>
                    <ImCancelCircle className='icon' onClick={() => cancelInvitation("Czy napewno chcesz anulować zaproszenie?",invitation.roomName, "cancel")}/>
                </span>
            </span>
            
        </div>

    );
};


export default InvitationItem;