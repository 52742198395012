import React, { useState } from 'react';
import './LoginContent.css';
import CustomLoginInput from '../../../CustomLoginInput/CustomLoginInput';
import CustomLoginButton from '../../../CustomLoginButton/CustomLoginButton';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const LoginContent = (props) => {
    const {sendLoginRequest,handleOnActivePanel, loading} = props;

    const [email, setEmail] = useState('');
    const [password,setPassword] = useState('');
    
    const handleOnChangeEmail = (e) => {
        setEmail(e.target.value.toLowerCase());
    }; 

    const handleOnChangePassword = (e) => {
        setPassword(e.target.value);
    };
    
    const loginForm = {
        email,
        password
    }
    return (
        <form className='login-content'>
            <span className='title-login'>Logowanie</span>
            <CustomLoginInput handleOnChange={handleOnChangeEmail} valueInput={email} labelName={"Email"} typeInput={"text"} placeholder={"Wprowadź email"}/>
            <CustomLoginInput handleOnChange={handleOnChangePassword} valueInput={password} labelName={"Hasło"} typeInput={"password"} placeholder={"Wprowadź hasło"}/>
            <span className="go-to-reset-button" onClick={()=>handleOnActivePanel("reset")}>Nie pamiętasz hasła?</span>
            <CustomLoginButton action={(e)=> sendLoginRequest(e, loginForm)} text={"Zaloguj"}>
                {loading ? <AiOutlineLoading3Quarters className="loading-icon" /> : "Zaloguj"}
            </CustomLoginButton>
        
            <span className="go-to-register-button" onClick={()=>handleOnActivePanel("register")}>Zarejestruj się</span>
            

        </form>
    );
};

export default LoginContent;