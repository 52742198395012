import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import './NavigationMenu.css';
import { togglePanel } from '../../../../helpers/actions';



const NavigationMenu = ({ isPanelActive, togglePanel }) => {
    const location = useLocation();

    const closeMenu = () => {
        togglePanel();
        const menu  = document.querySelector('.navigation-menu');
        menu.classList.remove('active');
        const body = document.querySelector('body');
        body.classList.toggle('menu-open');
    }

    useEffect(() => {
        const handleScroll = (articleId) => {
            if (articleId) {
                const article = document.getElementById(articleId);

                if (article) article.scrollIntoView({ behavior: 'smooth' });
                else window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };

        const articleId = location.hash.substr(1);

        if (articleId) handleScroll(articleId);
        else handleScroll();
        
    }, [location.hash]);

    return (
        <div className='navigation-menu'>
            <Link onClick={closeMenu} to='/'>Strona główna</Link>
            <Link onClick={closeMenu} to='/#Wprowadzenie'>Wprowadzenie</Link>
            <Link onClick={closeMenu} to='/#Prezentacja'>Prezentacja</Link>
            {/* <Link onClick={closeMenu} to='/#Cena'>Cena</Link> */}
            <Link onClick={closeMenu} to='/#Zasady'>Zasady</Link>
            <Link onClick={closeMenu} to='/#Kontakt'>Kontakt</Link>

        </div>
    );
};


const mapStateToProps = (state) => ({
    isPanelActive: state.app.isPanelActive,
  });
  
  const mapDispatchToProps = {
    togglePanel,
  };

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);

