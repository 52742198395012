import React, { useState } from 'react';
import './ResetPasswordPanel.css';
import CustomLoginButton from '../../../LoginPage/components/CustomLoginButton/CustomLoginButton';
import CustomLoginInput from '../../../LoginPage/components/CustomLoginInput/CustomLoginInput';

import { AiOutlineLoading3Quarters } from "react-icons/ai";

const ResetPasswordPanel = (props) => {
    const {changePassword} = props;

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');



    const handleOnChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleOnChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!password || !confirmPassword) {
            setErrorMessage('Wszytkie pola nie zostały wypełnione');
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Hasła nie są takie same!');
            return;
        }

        changePassword(password);
    };


    return (
        <section className='reset-password-panel'>
            <CustomLoginInput handleOnChange={handleOnChangePassword} valueInput={password} labelName={"Hasło"} typeInput={"password"} placeholder={"Wprowadź hasło"} />
            <CustomLoginInput handleOnChange={handleOnChangeConfirmPassword} valueInput={confirmPassword} labelName={"Potwierdź hasło"} typeInput={"password"} placeholder={"Potwierdź hasło"} />
            <CustomLoginButton action={handleSubmit} text="Stwórz konto" >
                {/* {loading ? <AiOutlineLoading3Quarters className="loading-icon" /> : "Stwórz konto"} */}
                Zmień
            </CustomLoginButton>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </section>
    )
};

export default ResetPasswordPanel;