import React, { useContext, useEffect, useState } from 'react';
import request from '../../helpers/request';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import InvitationItem from './components/InvitationItem/InvitationItem';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel'; // Import LoadingPanel

import './InvitationsPage.css';
import SimpleModal from '../../components/SimpleModal/SimpleModal';
import DecisionModal from '../../components/DecisionModal/DecisionModal';



import ImageError from '../../icons/lock.png';
import ImageQuestion from '../../icons/question.png';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../helpers/AuthContext';

const InvitationsPage = () => {
    const { setIsLoggedIn } = useContext(AuthContext);
    const [token, setToken] = useState(Cookies.get('access_token'));
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    
    const navigate = useNavigate();
    const [invitations, setInvitations] = useState([]);
    const [loading, setLoading] = useState(true); 


    const [showModalError, setShowModalError] = useState({status: false, message: ""});

    const [modalAction, setModalAction] = useState();
    const [showModalDescison, setShowModalDescison] = useState({status: false, message: ""});


    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});



    const handleOpenDecisionModal = (text, roomName, type) => {
        setShowModalDescison({status: true, message: text});
        if(type === "accept") setModalAction(()=> ()=> handleAcceptInvitation(roomName))
        else if (type === "cancel")setModalAction(()=> ()=> handleCancelInvitation(roomName))
        
    }

    
    const handleCloseDescisionModal = () => setShowModalDescison({status: false});

    const handleAcceptInvitation = (roomName) => {

        request.post(`/user/invitations/accepted/${user_id}`, {
            roomName
        },{
            headers: {
                'Authorization': token
            }
        })
        .then(res => {
            const newToken = res.data.access_token;
            setToken(newToken);
            Cookies.set('access_token', newToken);
            getInvitations();
            navigate('/user/rooms')
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message)
                getInvitations();
            } else if (status === 401){
                navigate('/user/rooms');
            }
          });
    };

    const handleCancelInvitation = (roomName) => {
        request.post(`/user/invitations/canceled/${user_id}`, {
            roomName
        },{
            headers: {
                'Authorization': token
            }
        })
        .then(res => {
            getInvitations();
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message);
                getInvitations();

            } else if (status === 401){
                navigate('/user/rooms');
            }
          });
    };

    const getInvitations = () => {
        request.get(`/user/invitations/${user_id}`,{
            headers: {
                'Authorization': token
            }
        })
        .then(res => {
            setInvitations(res.data.invitations.invitations);
            setLoading(false); 
        }) 
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message)
            } else if (status === 401) {
                    Cookies.remove('access_token');
                    setIsLoggedIn(false);
                    navigate('/login');
            }
          });
    };

    
    useEffect(() => {
        getInvitations();
    }, []);

    const invitationsList = invitations.map((invitation) => (
        <InvitationItem key={invitation.roomName} invitation={invitation} acceptInvitation={handleOpenDecisionModal} cancelInvitation={handleOpenDecisionModal}/>
    ));

    return (
        <UITemplate title={"Zaproszenia"}>
            {loading ? <LoadingPanel /> : (invitationsList.length > 0 ? invitationsList : <p className='lack-of-invitations'>Brak zaproszeń</p>)}

            <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>
            <DecisionModal handleCloseModal={handleCloseDescisionModal} action={modalAction} showModal={showModalDescison}> 
                <img className="icon-modal" src={ImageQuestion} alt="" />
            </DecisionModal>
        </UITemplate>
    );
};

export default InvitationsPage;
