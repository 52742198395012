import React from 'react';
import { useNavigate } from 'react-router-dom';
import failedImage from '../../../src/icons/failed.png';

import './PaymentFailedPage.css';
import UITemplate from '../../layouts/UITemplate/UITemplate';


const PaymentFailedPage = () => {

    const navigate = useNavigate();

    const handleOnClick = () => navigate('/user/rooms')
    return (
        <UITemplate>
            <section className='payment-failed-page'>
            <img src={failedImage} alt="" />
            <div className='failed-title'>Płatność nie powiodła się</div>
            <button onClick={handleOnClick} className='back-button'>Powrót</button>
        </section>
        </UITemplate>

    );
};

export default PaymentFailedPage;