const initialState = {
    isPanelActive: false,
  };
  
  const appReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'TOGGLE_PANEL':
        return {
          ...state,
          isPanelActive: !state.isPanelActive,
        };
      default:
        return state;
    }
  };
  
  export default appReducer;