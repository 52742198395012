import React from 'react';
import './TableItem.css';

const TableItem = ({team, index}) => {
    const { name, rank, code} = team;
    return (
        <div className='table-item'>
                    <span className='rank'>
                            <span className='circle'>{index}</span>
                    </span>
                    <span className='country'>
                        <span className='box'>
                            <span className='flag'>
                                <img src={`/Assets/images/flags/${code}.svg`} alt="" />
                            </span>
                            {name} 
                            <span className='fifa'>({rank})</span>
                        </span>
                    </span>

        </div>
    );
};

export default TableItem;