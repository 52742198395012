import React from 'react';
import './BracketItem.css';

const BracketItem = ({match, tablePosition1, tablePosition2}) => {
    const date = new Date(match.start);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const getTeamName = (team, isTeam1) => {
        if (team.code === "NZD") {
            return isTeam1 ? tablePosition1 : tablePosition2;
        } else {
            if((match.team_1.score !== null) && (match.team_2.score !== null)) {
                const winner = match.winner === "DRAW" ? checkWinner() : match.winner;
                if (winner === "") {
                    return team.code;
                } else if ((winner === "TEAM_1" && isTeam1) || (winner === "TEAM_2" && !isTeam1)) {
                    return <span className='winner'>{team.code}</span>;
                } else if ((winner === "TEAM_2" && isTeam1) || (winner === "TEAM_1" && !isTeam1)) {
                    return <span className='loser'>{team.code}</span>;
                } 
            } else return team.code;
        }
    };

    const checkWinner = () => {
        if (match.team_1.penalty > match.team_2.penalty) return "TEAM_1";
        else return "TEAM_2";
    }

    const getScore = () => {
        if (match.team_1.score === null || match.team_2.score === null || match.winner === "") {
            return `${day}.${month}`;
        } else if (match.winner === "DRAW") {
            const penaltyWinner = checkWinner();
            return (
                <>
                <span className={penaltyWinner === "TEAM_1" ? 'winner' : 'loser'}>
                    {match.team_1.score}
                </span> - 
                <span className={penaltyWinner === "TEAM_2" ? 'winner' : 'loser'}>
                    {match.team_2.score}
                </span>
            </>
            )
        } else {
            return (
                <>
                    <span className={match.winner === "TEAM_1" ? 'winner' : 'loser'}>
                        {match.team_1.score}
                    </span> - 
                    <span className={match.winner === "TEAM_2" ? 'winner' : 'loser'}>
                        {match.team_2.score}
                    </span>
                </>
            );
        }
    };

    return (
        <div className='bracket-item'>
            <div className='top'>
                <div className='team'>
                    <span className='flag'>
                        <img src={`/Assets/images/flags/${match.team_1.code}.svg`} alt="" />
                    </span>
                    <span className='name'>{getTeamName(match.team_1, true)}</span>
                </div>
                <div className='team'>
                    <span className='flag'>
                        <img src={`/Assets/images/flags/${match.team_2.code}.svg`} alt="" />
                    </span>
                    <span className='name'>{getTeamName(match.team_2, false)}</span>
                </div>
            </div>
            <div className='bottom'>{getScore()}</div>
        </div>
    );
};


export default BracketItem;