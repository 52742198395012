import React from 'react';

import './HomePage.css';
import Banner from './components/Banner/Banner';
import ArticlePanel from './components/ArticlePanel/ArticlePanel';
import Article from './components/Article/Article';
import IdeaImage from '../../images/idea.jpeg';
import RoomsImage from '../../images/rooms.jpeg';
import GameImage from '../../images/game.jpeg';
import ProductImage from '../../images/product.jpeg';
import RulesImage from '../../images/rules.jpeg';
import ContactImage from '../../images/contact.jpeg';
import Product from './components/Product/Product';

import MyBetsImage from '../../images/my_bets.jpg';
import RoomListImage from '../../images/room_list.jpg';
import RoomBetsImage from '../../images/room_bets.jpg';
import OfficialScoreImage from '../../images/official_score.jpg';
import RankingImage from '../../images/ranking.jpg';
import SettingsImage from '../../images/settings.jpg';
import InvitationsImage from '../../images/invitations.jpg';






import { TiPencil } from "react-icons/ti";
import { FaFlagCheckered } from "react-icons/fa";
import { TfiCup } from "react-icons/tfi";
import { IoIosFootball } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import SimpleSlider from './components/SimpleSlider/SimpleSlider';


const imgs = [RoomListImage,InvitationsImage,MyBetsImage, RoomBetsImage, OfficialScoreImage,RankingImage, SettingsImage]

const HomePage = () => {

    return (
        <section className='home-page'>
                <div className='nav'><IoIosFootball/> <span>TYPER</span></div>
                <Banner/>
                <ArticlePanel title="Wprowadzenie" >

                    <Article title="Zamysł" iconNumber={0} img={IdeaImage}> 
                        <span className='text'>Czy kiedykolwiek marzyłeś o tym, by stać się ekspertem w typowaniu wyników meczów? Czy chcesz sprawdzić swoje umiejętności przewidywania rezultatów spotkań, rywalizując z przyjaciółmi? Teraz masz okazję! To platforma stworzona dla pasjonatów sportu, którzy chcą wziąć udział w emocjonującej zabawie typowania meczów wraz ze swoimi bliskimi.
                        </span>
                    </Article>

                    <Article title="Pokoje" iconNumber={1} img={RoomsImage}> 
                        <span className='text'>Pokoje w aplikacji to dedykowane przestrzenie, gdzie gracze mogą rywalizować w typowaniu meczów, dzięki możliwości śledzenia rankingu.</span>
                    </Article>

                    <Article title="Jak to działa?" iconNumber={2} img={GameImage}> 
                        <div className='instruction-panel'>
                            <span className='number'>1</span>
                            <span className='info'>Zarejestruj się</span>
                        </div>
                        <div className='instruction-panel'>
                            <span className='number'>2</span>
                            <span className='info'>Dołącz do pokoju</span>
                        </div>
                        <div className='instruction-panel'>
                            <span className='number'>3</span>
                            <span className='info'>Typuj wyniki</span>
                        </div>
                        <div className='instruction-panel'>
                            <span className='number'>4</span>
                            <span className='info'>Zwyciężaj</span>
                        </div>
                    </Article>

                </ArticlePanel>

                <ArticlePanel title="Prezentacja">
                    <SimpleSlider imgs={imgs}/>
                </ArticlePanel>

                {/* <ArticlePanel title="Cena">
                    <Article title="Euro 2024" iconNumber={3} img={ProductImage}>
                        <Product name={"Pokój 5 osobowy"} price={100}/>
                        <Product name={"Pokój 10 osobowy"} price={200}/>
                        <Product name={"Pokój 15 osobowy"} price={300}/>
                        <Product name={"Pokój 20 osobowy"} price={400}/>
                        <Product name={"*Dowolny rozmiar"} price={20}/>
                        <span className='additional'>* możliwość kupna pokoju o dowolnym rozmiarze powyżej 20 osób. Cena dotyczy jednego gracza.</span>
                    </Article>
                </ArticlePanel> */}

                <ArticlePanel title="Zasady">
                    <Article title="Punktacja" iconNumber={4} img={RulesImage}>
                        <div className='rules-panel'>
                            <div className='top'>
                                <span className='number'>1</span>
                                <span className='info'>Punktacja</span>
                            </div>
                            <div className='bottom'>
                                <ul>
                                    <li>3 pkt za wytypowanie dokładnego wyniku</li>
                                    <li>1 pkt za wytypowanie niedokładnego wyniku</li>
                                    <li>0 pkt za źle wytypowany wynik</li>
                                </ul>
                            </div>
                        </div>
                        <div className='rules-panel'>
                            <div className='top'>
                                <span className='number'>2</span>
                                <span className='info'>Faza pucharowa</span>
                            </div>
                            <div className='bottom'>
                                <ul>
                                    <li>do punktacji uwzględniany jest wynik meczu po 90 min. w przypadku dogrywki po 120 min.</li>
                                    <li>ewentualne rzuty karne nie są brane pod uwagę</li>
                                </ul>
                                <span className='example-text'>Przykład</span>
                                <div className='example-panel'>

                                    <div className='container'>
                                        <div className='left'>
                                            <div className='icon-container'>
                                                <TiPencil className='icon'/>
                                            </div>
                                        </div>
                                        <div className='right'>
                                            <span className='example'>Gracz A typuje wynik 3:2</span>
                                            <span className='example'>Gracz B typuje wynik 1:1</span>
                                            <span className='example'>Gracz C typuje wynik 2:2</span>
                                        </div>

                                    </div>

                                    <div className='container'>
                                        <div className='left'>
                                            <div className='icon-container'>
                                                <FaFlagCheckered className='icon'/>
                                            </div>
                                        </div>
                                        <div className='right'>
                                            <span className='example'>Mecz kończy się po 120 min. wynikiem 2:2 (karne 4:3)</span>
                                        </div>
                                    </div>

                                    <div className='container'>
                                        <div className='left'>
                                            <div className='icon-container'>
                                                <TfiCup className='icon'/>
                                            </div>
                                        </div>
                                        <div className='right'>
                                            <span className='example'>Gracz A otrzymuje 0 pkt.</span>
                                            <span className='example'>Gracz B otrzymuje 1 pkt.</span>
                                            <span className='example'>Gracz C otrzymuje 3 pkt.</span>
                                        </div>
                                    </div>

                                   


                                </div>
                            </div>
                        </div>
                        <div className='rules-panel'>
                            <div className='top'>
                                <span className='number'>3</span>
                                <span className='info'>Tabela</span>
                            </div>
                            <div className='bottom'>
                                <ul>
                                    <li>wygrywa gracz, który posiada największą liczbę punktów</li>
                                    <li>statystyki: wynik dokładny, niedokładny, nietrafiony nie mają wpływu na pozycję</li>
                                </ul>
                            </div>
                        </div>
                    </Article>
                </ArticlePanel>

                <ArticlePanel title="Kontakt">
                    <Article title="Kontakt" iconNumber={5} img={ContactImage}>
                        <div className='contact-panel'>
                            <span className='conatct-text'>Jeśli masz jakiekolwiek pytania, proszę o kontakt drogą mailową.</span>
                            <span className='email'>
                                <HiOutlineMail className='email-icon'/>
                                <span className='email-text'>typer@nieweszlo.pl</span>
                            </span>
                        </div>
                    </Article>
                </ArticlePanel>



                


                
        </section>

    );
};


export default HomePage;