import React, { useEffect, useState } from 'react';
import './ChangeBetItem.css';
import { format } from 'date-fns';
import { MdOutlineModeEdit } from "react-icons/md";
import { IoMdClose } from "react-icons/io";




const ChangeBetItem = (props) => {
    const {match, betTeam1, betTeam2, matchId ,handleBetChange, action } = props;
    const {team_1, team_2, bet_id, start} = match;
    const startDate = start && format(start, "HH:mm");

    const [isActive, setIsActive] = useState(false);
    const [initialBetValues, setInitialBetValues] = useState({ betTeam1, betTeam2 });
    const [editedBetValues, setEditedBetValues] = useState({ betTeam1, betTeam2 });

    useEffect(() => {
        if (!isActive) {
            setEditedBetValues(initialBetValues);
        }
    }, [isActive, initialBetValues]);

    const handleToggleActive = () => {
        setIsActive(!isActive);
        if (!isActive) {
            setInitialBetValues({ betTeam1, betTeam2 });
        }
    };

    const handleBetChangeInternal = (team, value) => {
        setEditedBetValues(prevState => ({
            ...prevState,
            [team]: value
        }));
    };

    const handleSend = () => {
        action(bet_id, matchId, editedBetValues.betTeam1, editedBetValues.betTeam2); 
        setIsActive(false);
        setInitialBetValues(prevState => ({
            ...prevState,
            betTeam1: editedBetValues.betTeam1,
            betTeam2: editedBetValues.betTeam2,
        }));
    }


    return (
        <div className={`change-bet-item ${isActive ? 'active' : ''}`}>
            <span onClick={handleToggleActive}className='edit-button'>
                {!isActive ? <MdOutlineModeEdit/> : <IoMdClose/>}
            </span>
            <span className='top'>
                    <span className='time'>{startDate}</span>
            </span>

            <span className='middle'>
            <span className='flag'>
                <img src={`/Assets/images/flags/${team_1}.svg`} alt="" />
            </span>
                    <span className='country-name'>{team_1}</span>
                    { isActive ? (
                    <select className="score" onChange={(e) => handleBetChangeInternal('betTeam1', e.target.value)} value={editedBetValues.betTeam1}>
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    ): <span className="score-inactive">{betTeam1}</span>
                    }
                    <span className='separator'>:</span>
                    { isActive ? (
                    <select className="score" onChange={(e) => handleBetChangeInternal('betTeam2', e.target.value)} value={editedBetValues.betTeam2}>
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    ): <span className="score-inactive">{betTeam2}</span>
                    }

                    <span className='country-name'>{team_2}</span>
            
                <span className='flag'>
                <img src={`/Assets/images/flags/${team_2}.svg`} alt="" />
            </span>
            </span>
            {isActive ? (
                <span onClick={handleSend} className='action'>Zapisz</span>
            ): null}

        </div>
    );
};

export default ChangeBetItem;