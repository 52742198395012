import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useParams, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

import HomePage from '../../pages/HomePage/HomePage.js';
import LoginPage from '../../pages/LoginPage/LoginPage.js';
import { AuthContext } from '../../helpers/AuthContext.js';
import UserPage from '../../pages/UserPage/UserPage.js';
import ResetPasswordPage from '../../pages/ResetPasswordPage/ResetPasswordPage.js';
import ActivateAccountPage from '../../pages/ActivateAccountPage/ActivateAccountPage.js';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage.js';

const Content = () => {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = Cookies.get('access_token');
        setIsLoggedIn(!!token); 
    }, []);

    useEffect(() => {
        if (isLoggedIn && location.pathname === '/login') {
          navigate('/user/rooms');
        }
      }, [isLoggedIn, location.pathname, navigate]);


    const ProtectedRoute =  ({ setIsLoggedIn, element: Element, ...rest }) => {

        if (isLoggedIn) {
            
            return <Element {...rest} />;
        }
        else  return <LoginPage/>; 
      }
    
    return (
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/reset-password/:token' element={<ResetPasswordPage/>} />
            <Route path='/activate-account/:token' element={<ActivateAccountPage/>} />
            <Route path='/create-account' element={<LoginPage />} />
            <Route path='/user/*' element={<ProtectedRoute element={UserPage} />} />
            <Route path='*' element={<NotFoundPage/>} />

            
        </Routes>        
    );
};

export default Content;